function showSideBar() {
    $("#togglebar, #toggleclose").fadeIn();
    $('.toggle-bar').animate(
        {
            right: '0'
        }, 300, "linear", function () {
            $("#teszt").hide();
        }
    );
}

function hideSideBar() {
    $(".toggle-bar").animate({
            right: '-20%',
        },
        300, "linear",
        function () {
            $("#togglebar, #toggleclose").fadeOut();
            $("#teszt").show();
        }
    );
}

$(function () {


    $('iframe').each(function(){
        var iframe_rel = ($(this).attr('rel'));
        var iframe_id = ($(this).attr('id'));

       if (iframe_rel!=undefined) {

           $("#"+iframe_id).on('load',function(){
               $("#"+iframe_id).contents().find('body').css('cursor','pointer');

               $("#"+iframe_id).contents().find('body').click( function() {
                   location.href = iframe_rel;
               });
           });

       }

    });


    //$(".search-menu.active a").click();

    if ($(".search-menu.active").length == 1) {
        var menu_id = $(".search-menu.active").attr("id").split('-');
        setMasterCategory($(".search-menu.active a").attr("href"), menu_id[1], 1);
    }

    var search_ajax = 1;

    $('.ajax-search').change(function () {

        var sel_disabled = 0;


        if ($(this).val()==null)
        {
            $(this).select2("enable",false);
            sel_disabled =  $(this).attr('id');
        }

        var chkwidth = $('#search-width').val();
        var chkradius = $('#search-radius').val();
        var chkcategory = $('#search-category').val();

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax({
            type: 'POST',
            url: '/search-options',
            dataType: 'json',
            data: {
                meret: chkwidth,
                atmero: chkradius,
                category: chkcategory,
                categoryId: $('#category-id-detail').val()
            },
            success: function (response) {
                if (sel_disabled!=0) $("#"+sel_disabled).select2("enable");

               var width_options = response[0];
               var radius_options = response[1];
               var category_options = response[2];

                $('#search-width')
                    .find('option')
                    .remove()
                    .end()
                    .append(width_options)
                    .val(chkwidth);

                $('#search-radius')
                    .find('option')
                    .remove()
                    .end()
                    .append(radius_options)
                    .val(chkradius);

                $('#search-category')
                    .find('option')
                    .remove()
                    .end()
                    .append(category_options)
                    .val(chkcategory);
            }
        });


    });

    $('.mobil-ajax-search').change(function () {

        var chkwidth = $('#search-width').val();
        var chkradius = $('#search-radius').val();
        var chkcategory = $('#search-category').val();
        var chkbrand = $('#brand').val();


        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax({
            type: 'POST',
            url: '/search-options',
            dataType: 'json',
            data: {
                meret: chkwidth,
                atmero: chkradius,
                category: chkcategory,
                categoryId: $('#category-id-detail').val(),
                marka: chkbrand
            },
            success: function (response) {
                var width_options = '<option value="">Méret</option>'+response[0];
                var radius_options = '<option value="">Átmérő</option>'+response[1];
                var category_options = '<option value="">Kategória</option>'+response[2];
               // var brand_options = '<option value="">Méter</option>'+response[3];


                $('#search-width')
                    .find('option')
                    .remove()
                    .end()
                    .append(width_options)
                    .val(chkwidth);



                $('#search-radius')
                    .find('option')
                    .remove()
                    .end()
                    .append(radius_options)
                    .val(chkradius);






                $('#search-category')
                    .find('option')
                    .remove()
                    .end()
                    .append(category_options)
                    .val(chkcategory);



            }
        });
    });

    $('#motortipus-search-li a').click(setManufacturer());


    changeManufacturer();
    changeModel(0);
    //changeSince(0);
    //changeFront();
    //changeRear(0);


    /*
    $('#rear').change(function () {
        changeRear(1);
    });

    $('#front').change(function () {
        changeFront();
    });
    */

    $('#since').change(function () {
        changeSince(1);
    });

    $('#model').change(function () {
        changeModel(1);
    });

   /* $("#motortipus-search-li").click(function(){
        $('.tizeves-logo').hide();
        $('.tab-content-container').removeClass('col-lg-15');
        $('.tab-content-container').addClass('col-lg-18');
    });

    $("#marka-search-li, #pair-search-li, #meret-search-li").click(function(){
        $('.tizeves-logo').show();
        $('.tab-content-container').removeClass('col-lg-18');
        $('.tab-content-container').addClass('col-lg-15');
    });*/


    $('#manufacturer').change(function () {
        changeManufacturer();
    });

    $('#phone-number-img, #expert-more').click(function () {
        $('#expert-more-phones').modal("show");
    });

    $('#contact-button').click(function () {
        $('#contact-modal').modal("show");
    });
    $('#contact-modal').on('shown.bs.modal', function () {
        scaleCaptcha();
    })

    $(window).bind("load resize", function () {
        scaleCaptcha();
    });

    $('#delivery-button').click(function () {
        $('#delivery-modal').modal("show");
    });




    $('.pair-ajax-search').change(function () {


        var chkfront = $('#search-em').val();
        var chkrear = $('#search-hm').val();
        var chkcategory = $('#search-pair-category').val();


        var sel_disabled = 0;

        if ($(this).val()==null)
        {
            $(this).select2("enable",false);
            sel_disabled =  $(this).attr('id');
        }

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });

        $.ajax({
            type: 'POST',
            url: '/pair-search-options',
            dataType: 'json',
            data: {
                elso_meret: chkfront,
                hatso_meret: chkrear,
                pair_category: chkcategory,
                categoryId: $('#category-id-pair').val()
            },
            success: function (response) {
                var front_options = response[0];
                var rear_options = response[1];
                var category_options = response[2];

                if (sel_disabled!=0) $("#"+sel_disabled).select2("enable");

                $('#search-em')
                    .find('option')
                    .remove()
                    .end()
                    .append(front_options)
                    .val(chkfront);

                $('#search-hm')
                    .find('option')
                    .remove()
                    .end()
                    .append(rear_options)
                    .val(chkrear);

                $('#search-pair-category')
                    .find('option')
                    .remove()
                    .end()
                    .append(category_options)
                    .val(chkcategory);

            }
        });


    });

    $('.mobil-pair-ajax-search').change(function () {


        var chkfront = $('#search-em').val();
        var chkrear = $('#search-hm').val();
        var chkcategory = $('#search-pair-category').val();

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });

        $.ajax({
            type: 'POST',
            url: '/pair-search-options',
            dataType: 'json',
            data: {
                elso_meret: chkfront,
                hatso_meret: chkrear,
                pair_category: chkcategory,
                categoryId: $('#category-id-pair').val()
            },
            success: function (response) {
                var front_options = '<option value="">Első méret</option>'+response[0];
                var rear_options = '<option value="">Hátsó méret</option>'+response[1];
                var category_options = '<option value="">Kategória</option>'+response[2];

                $('#search-em')
                    .find('option')
                    .remove()
                    .end()
                    .append(front_options)
                    .val(chkfront);

                $('#search-hm')
                    .find('option')
                    .remove()
                    .end()
                    .append(rear_options)
                    .val(chkrear);

                $('#search-pair-category')
                    .find('option')
                    .remove()
                    .end()
                    .append(category_options)
                    .val(chkcategory);

            }
        });


    });

    $('.brand-ajax-search').change(function () {

        var chkwidth = $('#brand-width').val();
        var chkradius = $('#brand-radius').val();
        var chkcategory = $('#brand-category').val();
        var chkbrand = $('#brand').val();

        var sel_disabled = 0;

        if ($(this).val()==null)
        {
            $(this).select2("enable",false);
            sel_disabled =  $(this).attr('id');
        }


        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax({
            type: 'POST',
            url: '/search-options',
            dataType: 'json',
            data: {
                meret: chkwidth,
                atmero: chkradius,
                category: chkcategory,
                categoryId: $('#category-id-brand').val(),
                marka: chkbrand
            },
            success: function (response) {
                let width_options = response[0];
                let radius_options = response[1];
                let category_options = response[2];
                let brand_options = response[3];

                if (sel_disabled!=0) $("#"+sel_disabled).select2("enable");


                $('#brand-width')
                    .find('option')
                    .remove()
                    .end()
                    .append(width_options)
                    .val(chkwidth);

                if (chkwidth == null && $('#brand-width').find('option').length == 1) {
                    //$("#brand-width").select2("val", $('#brand-width').find('option').val());
                }

                $('#brand-radius')
                    .find('option')
                    .remove()
                    .end()
                    .append(radius_options)
                    .val(chkradius);

                if (chkradius == null && $('#brand-radius').find('option').length == 1) {
                    //$("#brand-radius").select2("val", $('#brand-radius').find('option').val());
                }

                $('#brand')
                    .find('option')
                    .remove()
                    .end()
                    .append(brand_options)
                    .val(chkbrand);

                if (chkbrand == null && $('#brand').find('option').length == 1) {
                    //$("#brand").select2("val", $('#brand').find('option').val());
                }

                $('#brand-category')
                    .find('option')
                    .remove()
                    .end()
                    .append(category_options)
                    .val(chkcategory);


                if (chkcategory == null && $('#brand-category').find('option').length == 1) {
                    // $("#brand-category").select2("val", $('#brand-category').find('option').val());
                }
            }
        });
    });

    $('.mobil-brand-ajax-search').change(function () {

        var chkwidth = $('#brand-width').val();
        var chkradius = $('#brand-radius').val();
        var chkcategory = $('#brand-category').val();
        var chkbrand = $('#brand').val();


        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax({
            type: 'POST',
            url: '/search-options',
            dataType: 'json',
            data: {
                meret: chkwidth,
                atmero: chkradius,
                category: chkcategory,
                categoryId: $('#category-id-brand').val(),
                marka: chkbrand
            },
            success: function (response) {
                var width_options = '<option value="">Méret</option>'+response[0];
                var radius_options = '<option value="">Átmérő</option>'+response[1];
                var category_options = '<option value="">Kategória</option>'+response[2];
                var brand_options = '<option value="">Méret</option>'+response[3];

                $('#brand-width')
                    .find('option')
                    .remove()
                    .end()
                    .append(width_options)
                    .val(chkwidth);

                $('#brand-radius')
                    .find('option')
                    .remove()
                    .end()
                    .append(radius_options)
                    .val(chkradius);


                $('#brand')
                    .find('option')
                    .remove()
                    .end()
                    .append(brand_options)
                    .val(chkbrand);

                $('#brand-category')
                    .find('option')
                    .remove()
                    .end()
                    .append(category_options)
                    .val(chkcategory);


            }
        });
    });

});


$(window).bind("load resize", function () {
    resizeSatisfiedCustomersRow();
});

$("#search-tab-control li a").click(function () {

    var selected = $(this).parent().attr('id');
    $('#search-container').css('background','#e7e7e7');

    switch (selected) {
        case 'motortipus-search-li':
            //$('.row.search-row .search-container').css('background', '#ffd200');
            $('.tizeves-logo').hide();
            $('.tab-content-container').removeClass('col-lg-15');
            $('.tab-content-container').addClass('col-lg-18');
            break;
        default:
            //$('.row.search-row .search-container').css('background', '#e7e7e7');
            $('.tizeves-logo').show();
            $('.tab-content-container').removeClass('col-lg-18');
            $('.tab-content-container').addClass('col-lg-15');
            break;
    }
});

$(window).bind("load", function () {
    /*
     EMAIL CÍM VÉDELEM
     */
    $(".sem").each(function () {
        var em = $(this).attr("data-e0") + "@" + $(this).attr("data-e1") + "." + $(this).attr("data-e2");
        $(this).html('<a href="mailto:' + em + '">' + em + '</a>');
    });
    $(".sem2").each(function () {
        var em = $(this).attr("data-e0") + "@" + $(this).attr("data-e1") + "." + $(this).attr("data-e2");
        $(this).attr('href', 'mailto:"' + em + '"');
    });

    /*
     TOOLTIP INI
     */
    $('[data-toggle="tooltip"]').tooltip();
});

function resetHeight(f) {
    $(f).each(
        function () {
            $(this).css("height", "auto");
        });
}

function resizeSatisfiedCustomersRow() {
    var findS = ".row.satisfied-customers-row .sc-item table";
    resetHeight(findS);
    var maxH = 0;
    $(findS).each(function () {
        if ($(this).height() > maxH) {
            maxH = $(this).height();
        }
    });
    $(findS).each(function () {
        $(this).height(maxH);
    });
}


function changeRear(refresh) {

    var selrear = $('#rear').val();

    if (selrear) {

        $('#rear-value').html($('#rear :selected').text());
        $('#rear-label').text('Hátsó méret:');

        $('#rear-div').show();
        $('#rear-sel').hide();

        if (refresh == 1) $("#type-search").click();

    }
    else {
        $('#rear-label').text('Hátsó méret:');
    }
}

function changeFront() {

    var selsince = $('#since').val();
    var selmodel = $('#model').val();
    var selfront = $('#front').val();
    var selrear = $('#rear').val();

    if (selfront) {

        $('#front-value').html($('#front :selected').text());
        $('#front-label').text('Első méret:');

        $('#front-div').show();
        $('#front-sel').hide();

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });

        $.ajax({
            type: 'POST',
            dataType: "json",
            url: '/type-search-options',
            data: {
                field: "front",
                model: selmodel,
                since: selsince,
                front: selfront
            },
            success: function (response) {

                var rear_options = response[0];

                $('#rear')
                    .find('option')
                    .remove()
                    .end()
                    .append('<option value="">Hátsó méret...</option>' + rear_options)
                    .val(selrear);


            }
        });
    }
    else {
        $('#front-label').text('Első méret:');
    }
}

function changeSince(refresh) {

    var selsince = $('#since').val();
    var selmodel = $('#model').val();
    //var selfront = $('#front').val();

    if (selsince) {

        $('#since-value').html($('#since :selected').text());
        $('#since-label').text('Évjárat:');

        $('#since-div').show();
        $('#since-sel').hide();

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });

        $.ajax({
            type: 'POST',
            dataType: "json",
            url: '/type-search-options',
            data: {
                field: "since",
                model: selmodel,
                since: selsince
            },
            success: function (response) {

                /*var front_options = response[0];

                $('#front')
                    .find('option')
                    .remove()
                    .end()
                    .append('<option value="">Első méret...</option>' + front_options)
                    .val(selfront);
                */


                //var man_options = response[0];
                var sizes = response;

                $('#front_size').val(sizes.front_size);
                $('#rear_size').val(sizes.rear_size);
                //$('#pressure').val(sizes.pressure);

                $('#front-div').html(sizes.front_size);
                $('#rear-div').html(sizes.rear_size);
                //$('#pressure-div').html(sizes.pressure);

                if (refresh == 1) $("#type-search").click();
            }
        });
    }
    else {
        $('#model-label').text('Válasz típust:');
    }
}

function changeModel(refresh) {

    var selmodel = $('#model').val();

    //console.log("model"+selmodel);

    if (selmodel) {

        //var selsince = $('#since').val();
        $('#model-value').html($('#model :selected').text());
        $('#model-label').text('Típus:');

        $('#model-div').show();
        $('#model-sel').hide();

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });

        $.ajax({
            type: 'POST',
            dataType: "json",
            url: '/type-search-options',
            data: {
                field: "model",
                model: selmodel
            },
            success: function (response) {

                //var since_options = response[0];

                /*$('#since')
                    .find('option')
                    .remove()
                    .end()
                    .append('<option value="">Évjárat...</option>' + since_options)
                    .val(selsince); */

                //$('#front_size').val(sizes.front_size);

                var sizes = response;

                //console.log(sizes);
                //console.log(refresh);

                $('#front_size').val(sizes.front_size);
                $('#rear_size').val(sizes.rear_size);
                //$('#pressure').val(sizes.pressure);

                $('#front-div').html(sizes.front_size);
                $('#rear-div').html(sizes.rear_size);
                //$('#pressure-div').html(sizes.pressure);

                if (refresh == 1) $("#type-search").click();
            }
        });
    }
    else {
        $('#model-label').text('Válasz típust:');
    }
}

function changeManufacturer() {
    var selman = $('#manufacturer').val();
    var selcat = $('#category-id-type').val();

    if (selman) {
        var selmodel = $('#model').val();

        $('#manufacturer-value').html($('#manufacturer :selected').text());
        $('#manufacturer-div').show();
        $('#manufacturer-label').text('Márka:');
        $('#manufacturer-sel').hide();

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });

        $.ajax({
            type: 'POST',
            dataType: "json",
            url: '/type-search-options',
            data: {
                field: "man",
                manufacturer: selman,
                c: selcat
            },
            success: function (response) {
                var model_options = response[0];

                $('#model')
                    .find('option')
                    .remove()
                    .end()
                    .append('<option value="">Típus...</option>' + model_options)
                    .val(selmodel);
            }
        });
    }
    else {
        $('#manufacturer-label').text('Válasz márkát:');
    }
}

function setManufacturer() {

        var selman = $('#manufacturer').val();
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });

        $.ajax({
            type: 'POST',
            dataType: "json",
            url: '/type-search-options',
            data: {
                field: "getman",
                c: $('#category-id-type').val()
            },
            success: function (response) {
                var options = response[0];

                $('#manufacturer')
                    .find('option')
                    .remove()
                    .end()
                    .append('<option value="">Márka...</option>' + options).val(selman);
            }
        });

}

function resetManufacturer(sel2) {
    $('#manufacturer-div').hide();
    $('#manufacturer-sel').show();
    if (sel2) {
        $("#manufacturer").select2().val(null).change();
    } else {
        $("#manufacturer").val(null).change();
    }
    $('#manufacturer-label').text('Válasz márkát:');
    resetModel(sel2);

}

function resetModel(sel2) {
    $('#model-div').hide();
    if (sel2) {

        $("#model").select2().val(null).change();
    } else {

        $("#model").val(null).change();
    }
    $('#model-sel').show();
    //$('#front-div').html('');
    //$('#rear-div').html('');
    //$('#pressure-div').html('');
    $('#model-label').text('Válasz típust:');
    resetSince(sel2);
}

function resetSince(sel2) {
    $('#since-div').hide();
    if (sel2) {
        $("#since").select2().val(null).change();
    } else {
        $("#since").val(null).change();
    }
    $('#since-sel').show();
    $('#front-div').html('');
    $('#rear-div').html('');
    $('#since-label').text('Válasz évjáratot:');
    //resetFront(sel2);
}

/*
function resetFront(sel2) {
    $('#front-div').hide();
    if (sel2) {

        $("#front").select2().val(null).change();
    } else {

        $("#front").val(null).change();
    }
    $('#front-sel').show();
    $('#front-label').text('Első méret:');
    resetRear(sel2)
}

function resetRear(sel2) {
    $('#rear-div').hide();
    if (sel2) {

        $("#rear").select2().val(null).change();
    } else {

        $("#rear").val(null).change();
    }
    $('#rear-sel').show();
    $('#rear-label').text('Hátsó méter:');
}
*/

function setBrand(brand_id) {
    $("#brand").val(brand_id).trigger("change");
    $('.nav-tabs  a[href="#meret-search"]').tab('show');
}

function setCategory(url) {
    var params = url.split('|');
    if (params[1]) {
        //$("#search-category").select2('val',params[1]);
        $("#search-category").val(params[1]);
        if ($("#search-category").val()==null)
        {
            $("#search-width").val('');
            $("#search-radius").val('');
            $("#search-category").append('<option value="'+params[1]+'"></option>');
        }
        $("#search-category").val(params[1]).trigger("change");

        $("#brand-category").val(params[1]);
        if ($("#brand-category").val()==null)
        {
            $("#brand-width").val('');
            $("#brand-radius").val('');
            $("#brand").val('');
            $("#brand-category").append('<option value="'+params[1]+'"></option>');
        }
        $("#brand-category").val(params[1]).trigger("change");


        $("#search-pair-category").val(params[1]);
        if ($("#search-pair-category").val()==null)
        {
            $("#search-em").val('');
            $("#search-hm").val('');
            $("#search-pair-category").append('<option value="'+params[1]+'"></option>');
        }
        $("#search-pair-category").val(params[1]).trigger("change");



        $("#select2-search-category-container").addClass('backgroundAnimated');

        setTimeout(function () {
            $("#select2-search-category-container").removeClass('backgroundAnimated');
        }, 3000);



        //$('.nav-tabs  a[href="#' + params[0] + '-search"]').tab('show');
        return false;
    }
    return true;
}

function setMasterCategory(url, menu_id, isActive) {

    //console.log(url+menu_id);

    if ($("#menu-" + menu_id).hasClass('active') && isActive == 0) {
        $("#categories-sub-menu-" + menu_id).hide();
        $("#menu-" + menu_id).removeClass('active')
        $('.search-menu .menu-caret-down i.fa').removeClass('fa-caret-up');
        $('.search-menu .menu-caret-down i.fa').addClass('fa-caret-down');
        return false;
    }

    var params = url.split('-c');

    if (url != '') {
        $("#search-tab-control li, #search-tab-control li a").removeClass('disabled');
        $("#meret-search-li a").attr('href', '#meret-search');
        $("#meret-search-li a").attr('data-toggle', 'tab');
        $("#marka-search-li a").attr('href', '#marka-search');
        $("#marka-search-li a").attr('data-toggle', 'tab');
        $("#pair-search-li a").attr('href', '#pair-search');
        $("#pair-search-li a").attr('data-toggle', 'tab');
        $("#motortipus-search-li a").attr('href', '#motortipus-search');
        $("#motortipus-search-li a").attr('data-toggle', 'tab');
    }

    if (params[1]) {

        var category_id = params[1];

        if (category_id == 6) {
            $("#search-container").hide();
            $("#motorapolas-container").show();
        }
        else if (category_id == 3) {
            $("#marka-search-li, #marka-search-li a").addClass('disabled');
            $("#marka-search-li a").removeAttr('data-toggle');
            $("#marka-search-li a").attr('href', '#');

            $("#pair-search-li, #pair-search-li a").addClass('disabled');
            $("#pair-search-li a").removeAttr('data-toggle');
            $("#pair-search-li a").attr('href', '#');

            $("#meret-div").show();

            $("#search-container").show();
            $("#motorapolas-container").hide();
            resetManufacturer(true);

            //$("#meret-search-li a").click();

        }
        else if (category_id == 5) { //tömlő

            $("#meret-div").hide();

            $("#search-width").val('');

            $("#marka-search-li, #marka-search-li a").addClass('disabled');
            $("#marka-search-li a").removeAttr('data-toggle');
            $("#marka-search-li a").attr('href', '#');
            $('.speed-search').hide();

            $("#pair-search-li, #pair-search-li a").addClass('disabled');
            $("#pair-search-li a").removeAttr('data-toggle');
            $("#pair-search-li a").attr('href', '#');

            $("#search-container").show();
            $("#motorapolas-container").hide();



            $("#meret-search-li a").click();


        }
        else {

            $("#meret-div").show();

            $("#marka-search-li, #marka-search-li a").removeClass('disabled');
            $("#marka-search-li a").attr('href', '#marka-search');
            $("#marka-search-li a").attr('data-toggle', 'tab');
            $('.speed-search').show();

            $("#pair-search-li, #pair-search-li a").removeClass('disabled');
            $("#pair-search-li a").attr('href', '#pair-search');
            $("#pair-search-li a").attr('data-toggle', 'tab');

            //páros keresőben
            $("#category-id-pair").val(category_id);
            $("#category-id-pair").change();


            $("#search-container").show();
            $("#motorapolas-container").hide();


            resetManufacturer(true);




        }

        //méret keresőben
        $("#category-id-detail").val(category_id);
        $("#category-id-detail").change();

        //márka keersőben
        $("#category-id-brand").val(category_id);
        $("#category-id-brand").change();

        $("#category-id-type").val(category_id);

        $(".categories-sub-menu").hide();
        $("#categories-sub-menu-" + menu_id).show();
        $(".search-menu").removeClass('active');
        $("#menu-" + menu_id).addClass('active');

        $('.search-menu .menu-caret-down i.fa').removeClass('fa-caret-up');
        $('.search-menu .menu-caret-down i.fa').addClass('fa-caret-down');
        $("#menu-" + menu_id + ' .menu-caret-down i.fa').addClass('fa-caret-up');

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });




            $.ajax({
                type: 'POST',
                dataType: "json",
                url: '/banner-slider',
                data: {
                    category_id: category_id
                },
                success: function (response) {

                    if (response.slides != undefined && response.slides != '' && typeof initvegas !== "undefined") {
                        slides = response.slides;
                        slides_min = response.slides_min;

                        $("#vegas-navi ul").html(response.navi);
                        initvegas();
                    }
                }
            });

        return false;
    }

    if (url != '') {
        return true;
    }
    else {
        $(".categories-sub-menu").hide();
        $("#categories-sub-menu-" + menu_id).show();
        $(".search-menu").removeClass('active');
        $("#menu-" + menu_id).addClass('active');

        $("#search-tab-control li, #search-tab-control li a").addClass('disabled');
        $("#search-tab-control li a").removeAttr('data-toggle');
        $("#search-tab-control li a").attr('href', '#');


        $('.search-menu .menu-caret-down i.fa').removeClass('fa-caret-up');
        $('.search-menu .menu-caret-down i.fa').addClass('fa-caret-down');
        $("#menu-" + menu_id + ' .menu-caret-down i.fa').addClass('fa-caret-up');

        return false;
    }
}

function setMobilCategory(url, menu_id) {
    var params = url.split('-c');
    if (params[1]) {

        var category_id = params[1];

        if (category_id == 6) {
            $(".mobil-search-container").hide();
            $("#motorapolas-container").show();
        }
        else {
            $(".mobil-search-container").show();
            $("#motorapolas-container").hide();
        }

        //tömlők
        if (category_id == 5) {
            $("#marka-search-li").hide();
            $("#pair-search-li").hide();
            $("#meret-div").hide();
        } else if (category_id == 3)
        {
            $("#marka-search-li").hide();
            $("#pair-search-li").show();
            $("#meret-div").show();
        }
        else
        {
            $("#marka-search-li").show();
            $("#pair-search-li").show();
            $("#meret-div").show();
        }

        //méret keresőben
        $("#search-width").val('');
        $("#search-radius").val('');
        $("#category-id-detail").val(category_id);
        $("#category-id-detail").change();

        //páros keresőben
        $("#category-id-pair").val(category_id);
        $("#category-id-pair").change();

        //márka keersőben
        $("#category-id-brand").val(category_id);
        $("#category-id-brand").change();

        //típus keersőben
        $("#category-id-type").val(category_id);
        //$("#category-id-type").change();

        $(".categories-sub-menu").hide();
        $("#categories-sub-menu-" + menu_id).show();
        $(".search-menu").removeClass('active');
        $("#menu-" + menu_id).addClass('active');

        return false;
    }

    if (url != '') {
        return true;
    }
    else {
        $(".categories-sub-menu").hide();
        $("#categories-sub-menu-" + menu_id).show();
        $(".search-menu").removeClass('active');
        $("#menu-" + menu_id).addClass('active');
        return false;
    }
}


function ajaxList(){



    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    $.ajax({
        type: 'POST',
        dataType : "json",
        url: '/tipus-kereso',
        data: {
            manufacturer : $('#manufacturer').val(),
            model : $('#model').val(),
            front : $('#front_size').val(),
            rear : $('#rear_size').val(),
            c : $('#category-id-type').val(),
            category_id: $('#typeCategory').val(),
            brand: $('#typeBrand').val(),
            mod: $('#typeMod').val()
        },
        success: function (response) {

            history.replaceState( {} , 'tipus-kereso', 'tipus-kereso?manufacturer='+$('#manufacturer').val()+'&model='+$('#model').val() +
            '&front='+$('#front_size').val()+'&rear='+$('#rear_size').val()+'&c='+$('#category-id-type').val()+'&category_id=' +
            $('#typeBrand').val()+'&brand='+'&mod='+$('#typeMod').val());

            $("#prio-product-list").html(response[0]);
            $("#product-list").html(response[1]);

            $('#typeCategory').select2({allowClear: true});
            $('#typeBrand').select2({allowClear: true});


            $('.compare input[type="checkbox"].icheck').iCheck(
                {
                    checkboxClass: 'icheckbox_minimal',
                    radioClass: 'iradio_minimal'
                });



            $('.compare input[type="checkbox"]').on('ifChanged', function (event) {
                let product_id = $(this).attr('id').split('_')[1];
                getCompare(product_id, event.target.checked);
                $('.compare-button').click();
            });


            $('.compare-button').click(function () {

                $.ajaxSetup(
                    {
                        headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')}
                    });

                $.ajax(
                    {
                        type: 'get',
                        dataType: 'html',
                        url: '/compare-product-review',
                        success: function (compare_html) {
                            if (!compare_html) {

                                var he = $("#comapare-panel").outerHeight() - $("#comapare-panel .panel-heading").outerHeight();
                                $('#comapare-panel').animate({bottom: '-' + he + 'px'});
                                $('#comapare-panel #compare-close-btn i').removeClass("fa-arrow-down").addClass("fa-arrow-up");

                                return;
                            }
                            showCompare(compare_html);
                            compareVisible = true;
                            $('#comapare-panel').animate({bottom: '0px'});
                            $('#comapare-panel #compare-close-btn i').removeClass("fa-arrow-up").addClass("fa-arrow-down");
                        }
                    });
            });


            /*$('input[type="radio"].icheck').on('ifChanged', function (event) {
                ajaxList();
            });*/

        }
    });
}





// Resize reCAPTCHA to fit width of container
// Since it has a fixed width, we're scaling
// using CSS3 transforms
// ------------------------------------------
// captchaScale = containerWidth / elementWidth

function scaleCaptcha(elementWidth) {
    // Width of the reCAPTCHA element, in pixels
    var reCaptchaWidth = 304;
    // Get the containing element's width
    var containerWidth = $('.g-recaptcha-container').width();
    // Calculate the scale
    var captchaScale = containerWidth / reCaptchaWidth;
    // Apply the transformation
    $('.g-recaptcha').css({
        'transform': 'scale(' + captchaScale + ')'
    });
}

$(function () {

    // Initialize scaling
    scaleCaptcha();
});


$(window).bind("resize", function () {
    setTimeout(scaleCaptcha, 100)
});