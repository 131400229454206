/* GÖRGETÉS ELEMHEZ */
function scrollToId(i, d, s, o) {
    if (d == undefined) {
        var d = 100
    }
    if (s == undefined) {
        var s = 1000
    }
    if (o == undefined) {
        var o = -10
    }
    try {
        $("html, body").delay(d).animate({scrollTop: $("#" + i).offset().top + o}, s)
    } catch (err) {
    }
};

/* INPUT SZŰRÉS CSAK BETŰ, SZÁM, STB */
var telNumber = /[1234567890\/\(\)\+\-\s]/g;
var digitsOnly = /[1234567890]/g;
var integerOnly = /[0-9\.]/g;
var floatOnly = /[0-9\.\,]/g;
var alphaOnly = /[A-Za-z]/g;
var usernameOnly = /[0-9A-Za-z\._-]/g;
function restrictInput(h, i, j, g) {
    var code = null;
    if (!i) {
        var i = window.event
    }
    if (i.keyCode) {
        code = i.keyCode
    } else {
        if (i.which) {
            code = i.which
        }
    }
    var e = String.fromCharCode(code);
    if (code == 27) {
        this.blur();
        return false
    }
    if (!i.ctrlKey && code != 9 && code != 8 && code != 36 && code != 37 && code != 38 && (code != 39 || (code == 39 && e == "'")) && code != 40 && code != 45) {
        if (e.match(j)) {
            if (g == "checkdot") {
                var val = $(h).val();

                if ((val.indexOf(".") != -1 || val.indexOf(",") != -1) && ((code < 48 || code > 57) && (code < 96 || code > 15) || code == 46)) {
                    return !isNaN(h.value.toString() + e)
                } else {
                    return true
                }
            } else {
                return true
            }
        } else {
            return false
        }
    }
}
function replaceComa(a) {
    var b = $(a).val();
    b = b.replace(/,/g, ".");
    $(a).val(b)
};
function replaceDOT(b) {
    var a = $(b).val();
    $(b).val(a.replace(/\./g, ","))
};

$(function () {
    $('.restrictInput.telNumber').each(function () {
        $(this).attr("onkeypress", "return restrictInput(this, event, telNumber);");
    });
    $('.restrictInput.digitsOnly').each(function () {
        $(this).attr("onkeypress", "return restrictInput(this, event, digitsOnly);");
    });
    $('.restrictInput.integerOnly').each(function () {
        $(this).attr("onkeypress", "return restrictInput(this, event, integerOnly);");
    });
    $('.restrictInput.floatOnly:not(.checkdot)').each(function () {
        $(this).attr("onkeypress", "return restrictInput(this, event, floatOnly);");
    });
    $('.restrictInput.floatOnly.checkdot').each(function () {
        $(this).attr("onkeypress", "return restrictInput(this, event, floatOnly, 'checkdot');");
    });
    $('.restrictInput.alphaOnly').each(function () {
        $(this).attr("onkeypress", "return restrictInput(this, event, alphaOnly);");
    });
    $('.restrictInput.usernameOnly').each(function () {
        $(this).attr("onkeypress", "return restrictInput(this, event, usernameOnly);");
    });
    $('.replaceDOT').each(function () {
        $(this).attr("onkeyup", "replaceDOT(this);");
    });
    $('.replaceComa').each(function () {
        $(this).attr("onkeyup", "replaceComa(this);");
    });
});